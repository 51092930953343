import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/organisms/seo';
import RichText from '../components/atoms/richtext';
import { media } from '../utils/media-queries';

const LegalPage = ({ data }) => {
  const { _rawPrivacy, _rawDisclaimer } = data.legalNotice.nodes[0];

  return (
    <>
      <SEO siteTitle="Legal" />
      <StyledLegal>
        <h2 className="large disclaimer">Disclaimer</h2>
        <RichText blocks={_rawDisclaimer} />
        <h2 className="large privacy">Privacy Policy</h2>
        <RichText blocks={_rawPrivacy} />
      </StyledLegal>
    </>
  );
};

const StyledLegal = styled.section`
  margin-top: var(--sp-5);
  padding: var(--sp-1-3);
  width: 95%;
  padding-right: 15vw;

  h2,
  p {
    padding-bottom: var(--sp-2);
  }

  .privacy {
    padding-top: var(--sp-2);
  }

  .disclaimer {
    padding-top: var(--sp-5);
  }

  @media ${media.M} {
    width: 50%;
  }
`;

export const query = graphql`
  query LegalNotice {
    legalNotice: allSanityLegalNotice {
      nodes {
        _rawPrivacy
        _rawDisclaimer
      }
    }
  }
`;

LegalPage.propTypes = {
  data: PropTypes.object,
};

export default LegalPage;
